// This is so that we don't block the main thread until the page is fully loaded
window.addEventListener('load', () => {
//String we get from helpscout
    !function (e, t, n) {
        function a() {
            var e = t.getElementsByTagName("script")[0], n = t.createElement("script");
            n.type = "text/javascript", n.async = !0, n.src = "https://beacon-v2.helpscout.net", e.parentNode.insertBefore(n, e)
        }

        if (e.Beacon = n = function (t, n, a) {
            e.Beacon.readyQueue.push({method: t, options: n, data: a})
        }, n.readyQueue = [], "complete" === t.readyState) return a();
        e.attachEvent ? e.attachEvent("onload", a) : e.addEventListener("load", a, !1)
    }(window, document, window.Beacon || function () {
    })
    ;
    window.Beacon('init', '41b3022e-9e0e-4803-b18e-24d6d1e98ec3'
    )
})